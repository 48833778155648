* {
  outline: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.required:after {
  content: "(Wajib)";
  @apply text-danger-main text-xs pl-1;
}

/* disable input password edge */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* disable number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*scrollbar*/

.asside::-webkit-scrollbar-thumb {
  @apply rounded-full border-[1px] border-solid border-[#808080]/80 bg-black/0;
}

/* width */
*::-webkit-scrollbar {
  @apply h-2 w-2;
}

/* Track */
*::-webkit-scrollbar-track {
  @apply hidden;
}

*::-webkit-scrollbar-corner {
  @apply hidden;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  @apply bg-white-500 hover:bg-white-500/90;
}

.pupil {
  transform: rotateX(32deg) rotateZ(45deg) rotateY(-10deg);
  box-shadow: 0 0 4px #000;
  @apply -top-1 left-[20px];
}

.animate-eye {
  animation: spin 5s ease-in-out infinite;
}

@keyframes spin {
  0%,
  100% {
    transform: rotate(0deg) scale(1);
  }

  60% {
    transform: rotate(23deg) scale(0.95);
  }
}

.animate-move {
  animation: move 5s ease-in-out infinite;
}

.animate-shadow {
  animation: shadow 5s ease-in-out infinite;
  animation-delay: -5000ms;
}

@keyframes move {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  60% {
    transform: translate3d(20px, -10px, 0);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  60% {
    transform: translate3d(20px, -10px, 0) scale(0.92);
  }
}

.foto-tentang-bisnis-swiper .swiper-pagination {
  @apply bottom-0 !important;
}

.foto-tentang-bisnis-swiper .swiper-pagination-bullet {
  @apply h-4 w-4 bg-white-400 opacity-100 !important;
}

.foto-tentang-bisnis-swiper .swiper-pagination-bullet-active {
  @apply h-4 w-4 bg-primary-main !important;
}

/* input color */
#primary_color {
  border: none;
  outline: none;
  -webkit-appearance: none;
}

#primary_color::-webkit-color-swatch-wrapper {
  padding: 0;
}
#primary_color::-webkit-color-swatch {
  border: none;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #d9c7ff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
